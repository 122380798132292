export const API_VERSION = "v1.0";
const HOST_URL = `brijeurope.brij.tech`;
const LIVEBASEURL = `https://brijeurope-api.brij.tech`;
const DEVBASEURL = `http://localhost:3800`;
// const DEVBASEURL = `https://cinematic-server-gdc-3.brij.tech`;
// const DEVBASEURL = `http://shringar.brij.tech:3950`;
export const BASEURL = window.location.host === HOST_URL ? LIVEBASEURL : DEVBASEURL;
export const IMG_BASEURL = BASEURL;
export const ARENA_TOKEN = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbiI6IjkwMGQxMmI3LTNkNDYtNDMwMC1hZDAxLThmMDViOWUxMmFlZSIsImlhdCI6MTY4MjQ5NDY1Mn0.syjx5tEk24fZpX5KGW7hlFwF-aINnn557T-rtu_GoXQ`;
export const TOKEN = ARENA_TOKEN;
